import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import { 
  container,
  content,
  header,
  h1
} from "../styles/blog-post.module.css"

export default function BlogPost({ data }: any) {
  const post = data.markdownRemark
  return (
    <Layout>
      <header className={header}>
        <div className={container}>
          <h1 className={h1}>Blog</h1>
        </div>
      </header>
      <div className={container}>
        <h1>{post.frontmatter.title}</h1>
        <div className={content} dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`